<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                v-if="!readOnly"
                id="field-account"
                label-cols-lg="2"
                :label="$t('PAYMENTS.ACCOUNT')"
                label-for="field-tariff"
              >
                <CompactSuggestion
                  suggestion-processor="accountDetailedSuggestionProcessor"
                  :serializer="(s) => s.description"
                  v-model="dto.accountId"
                  size="sm"
                  :placeholder="$t('PAYMENTS.ACCOUNT')"
                >
                </CompactSuggestion>
              </b-form-group>

              <b-form-group
                id="field-registered-amount"
                label-cols-lg="2"
                :label="$t('PAYMENTS.REGISTERED_AMOUNT')"
                label-for="field-registered-amount-input"
              >
                <b-form-input
                  id="field-registered-amount-input"
                  v-model="dto.registeredAmount"
                ></b-form-input>
              </b-form-group>
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button
            variant="success"
            v-if="!readOnly"
            v-on:click="onSaveClicked"
            >{{ $t("COMMON.SAVE") }}</b-button
          >
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";
import Application from "../../application";
import { mapState } from "vuex";
import CompactSuggestion from "../component/CompactSuggestion";

export default {
  name: "payment",
  mixins: [SuggestionProcessorMixin],
  components: { CompactSuggestion },
  data() {
    return {
      save: {
        resource: "platform/api/payment/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        accountId: null,
        registeredAmount: null,
      },

      showOverlay: true,
      constants: Application.constants,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PAYMENTS"), route: "/payments" },
      { title: this.$t("PAYMENTS.CREATE_NEW_PAYMENT") },
    ]);
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    readOnly() {
      return (
        !this.user.grants.includes("SYSTEM:ADMIN") &&
        this.dto.id != null &&
        this.dto.global == true
      );
    },
  },

  created: async function () {
    this.showOverlay = false;
  },

  methods: {
    onSaveClicked: function () {
      this.showOverlay = true;
      const request = this.save;
      request.dto = { ...this.dto };

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError)
        .finally(() => {
          this.showOverlay = false;
        });
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
